import React from "react";

export const LegalServicesCard = ({
  isDarkMode,
  styles,
  title,
  list,
  image,
  desc,
  direction,
  primary_bg,
}) => {
  return (
    <div
      className={`grid grid-cols-1 lg:grid-cols-2 overflow-hidden max-h-[700px] ${
        direction === "ltr" ? "" : "direction-rtl"
      }`}
    >
      {!primary_bg ? (
        <div
          className={`col-span-2 ${styles.padding} ${
            isDarkMode
              ? "bg-blackColor text-whiteColor"
              : "bg-whiteColor text-blackColor"
          }`}
        >
          <div className="flex flex-col gap-2 items-start">
            <div className="flex flex-col md:flex-row items-start gap-8 md:gap-2 justify-between border-b-2 py-4 w-full">
              <div className="w-fit">
                <div
                  className={`h-[2px] ${
                    isDarkMode ? "bg-whiteColor" : "bg-blackColor"
                  } w-full`}
                ></div>
                <h2 className={`${styles.h2Text}  font-bold`}>{title}</h2>
              </div>
              <div className="">{list}</div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`col-span-2 ${styles.paddingTitle} bg-primary text-white`}
        >
          <div className="flex flex-col md:flex-row items-start gap-8 md:gap-2 justify-between border-b-2 py-4 w-full">
            <div className="w-fit">
              <div className={`h-[2px] bg-whiteColor w-full`}></div>
              <h2 className={`${styles.h2Text}  font-bold`}>{title}</h2>
            </div>
            <div className="">{list}</div>
          </div>
        </div>
      )}

      <div className="col-span-1 h-full lg:h-full overflow-hidden">
        <img className="w-full h-full object-cover" src={image} alt="" />
      </div>
      {!primary_bg ? (
        <div
          className={`col-span-1 flex h-full w-full justify-start ${
            isDarkMode
              ? "bg-blackColor text-whiteColor"
              : "bg-whiteColor text-blackColor"
          } flex flex-col gap-8 ${styles.padding}`}
        >
          <div className="flex flex-col ">{desc}</div>
        </div>
      ) : (
        <div
          className={`col-span-1 bg-primary text-white flex flex-col gap-8 ${styles.padding} h-full justify-center`}
        >
          <div className="flex flex-col">
            <p className={`${styles.pText} `}>{desc}</p>
          </div>
        </div>
      )}
    </div>
  );
};
