import React from "react";
import { styles } from "../../styles";
import { SupportEngine } from "../supportEngine/SupportEngine";
import { Socials } from "../global/Socials";

export const Footer = () => {
  return (
    <>
      {/* <div
        className={`fixed bottom-0 gap-4 z-20 right-0 ${styles.paddingX} hidden lg:block`}
      >
        <SupportEngine />
      </div> */}

      <div
        className={` ${styles.footerPadding} w-full flex flex-col gap-0  ${styles.pText} bg-blackColor   text-white overflow-hidden`}
      >
        <Socials />
      </div>
      <div
        className={` ${styles.footerPadding} ${styles.pText}   text-white bg-primary flex items-center justify-center`}
      >
        <p>© Copywrite by UKA business.</p>
      </div>
    </>
  );
};
