import React, { useEffect, useRef, useState } from "react";
import { styles } from "../../styles";
// import Img from "../../assets/images/home/how-it-works.jpg";
import emailjs from "@emailjs/browser";
import { AiFillCheckCircle } from "react-icons/ai";

import { Button } from "../global/Button";

import { useHomeContext } from "../../context/HomeContext";

import { BsTiktok } from "react-icons/bs";
import { SimpleMap } from "../global/SimpleMap";
import { AlertCard } from "../global/AlertCard";
import { Socials } from "../global/Socials";

export const ContactUs = () => {
  const { isDarkMode } = useHomeContext();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [successOrNot, setSuccessOrNot] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(false);
      }, 5000);
    }
  }, [alert]);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    // emailjs
    //   .sendForm(
    //     "service_byhssle",
    //     "template_8ml7fdm",
    //     form.current,
    //     "upjt3tBMnT7cfT2Hf"
    //   )
    emailjs
      .sendForm(
        "service_4dd13qs",
        "template_ksdarfr",
        form.current,
        "QMQo_tPLEXR5HC5JH"
      )
      .then(
        (result) => {
          console.log(result.text);
          setLoading(false);
          setAlert(true);
          setSuccessOrNot(true);
        },
        (error) => {
          setLoading(false);
          setAlert(true);
          setSuccessOrNot(false);
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <AlertCard
        alert={alert}
        successOrNot={successOrNot}
        isDarkMode={isDarkMode}
      />

      <div
        id={"contactUs"}
        className={`w-full grid grid-cols-1 lg:grid-cols-3 gap-8 ${
          isDarkMode ? "bg-blackColor" : "bg-whiteColor"
        } min-h-[500px]  overflow-hidden ${
          isDarkMode ? "text-whiteColor" : "text-blackColor"
        } ${styles.padding}`}
      >
        <div className="flex flex-col items-start justify-between mb-8 lg:mb-0 w-full">
          <div className="flex flex-col gap-2 w-full">
            <h4 className={`${styles.h4Text}`}>Let's Connect</h4>
            {/* <p className={`${styles.pText}`}>
            I'm a paragraph. Click here to add your own text and edit me. I’m a
            great place for you to tell a story and let your users know a little
            more about you.
          </p> */}
            {/* <SimpleMap /> */}
            <iframe
              className="w-full h-[300px] address-map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.593141925127!2d55.27030057491627!3d25.183212232267906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6927bd337f59%3A0x64bd0851d1b68e7!2sBurlington%20Tower!5e0!3m2!1sen!2sde!4v1694610868102!5m2!1sen!2sde"
              style={{ border: "0" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="back-to-top mt-4">
            <Button
              redirect={scrollToTop}
              customClass={` min-w-[200px] outline-1  ${
                isDarkMode ? "text-whiteColor" : "text-blackColor"
              } hover:bg-primary hover:text-whiteColor`}
              title={"Scroll To Top"}
            />
          </div>
        </div>
        <div className="flex flex-col items-start justify-between mb-8 lg:mb-0">
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-2">
              <h4 className={`${styles.h4Text}`}>Address</h4>
              <p className={`${styles.pText}`}>
                Suite 9, Office No. 903 Burlington Tower, Business Bay, Dubai,
                UAE
              </p>
            </div>
            <div className="flex flex-col gap-2">
              <h4 className={`${styles.h4Text}`}>Email</h4>
              <p className={`${styles.pText}`}>info@ukabusiness.com</p>
              <p className={`${styles.pText}`}>mona@ukabusiness.com</p>
            </div>
            <div className="flex flex-col gap-2">
              <h4 className={`${styles.h4Text}`}>Phone</h4>
              <p className={`${styles.pText}`}>+971 58 118 3944</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start justify-between">
          <form
            ref={form}
            onSubmit={sendEmail}
            className="flex flex-col gap-8 w-full"
          >
            <div className="flex flex-col lg:flex-row items-center gap-4 w-full">
              <div className="flex flex-col gap-1 w-full">
                <label for="from_name" className={`${styles.pText}`}>
                  Name
                </label>
                <input
                  required
                  type="text"
                  name="from_name"
                  className={`px-2 py-1 bg-transparent ${
                    isDarkMode ? "border-whiteColor" : "border-blackColor"
                  } border-bottom  hover:border-primary`}
                />
              </div>
              {/* <div className="flex flex-col gap-1 w-full">
              <label for="" className={`${styles.pText}`}>
                Last Name
              </label>
              <input
                className={`px-2 py-1 bg-transparent ${
                  isDarkMode ? "border-whiteColor" : "border-blackColor"
                } border-bottom  hover:border-primary`}
                type="text"
              />
            </div> */}
            </div>

            <div className="flex flex-col gap-1 ">
              <label for="" className={`${styles.pText}`}>
                Email
              </label>
              <input
                required
                type="email"
                name="user_email"
                className={`px-2 py-1 bg-transparent ${
                  isDarkMode ? "border-whiteColor" : "border-blackColor"
                } border-bottom  hover:border-primary`}
              />
            </div>
            <div className="flex flex-col gap-1 ">
              <label for="" className={`${styles.pText}`}>
                Message
              </label>
              <input
                name="message"
                type="text"
                className={`px-2 py-1 bg-transparent ${
                  isDarkMode ? "border-whiteColor" : "border-blackColor"
                } border-bottom  hover:border-primary`}
              />
            </div>

            <Button
              redirect={() => {}}
              loading={loading}
              customClass={` min-w-[200px] ${
                isDarkMode ? "text-whiteColor" : "text-blackColor"
              } outline outline-1 w-full hover:bg-primary hover:text-whiteColor `}
              title={"Send"}
            />
          </form>
        </div>
      </div>
    </>
  );
};
