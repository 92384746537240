import React, { useState } from "react";
import { Button } from "./Button";

export const MemberCard = ({
  memberImg,
  memberName,
  memberPosition,
  memberDescription,
  memberPhoneNumber,
  memberEmail,
  styles,
  isDarkMode,
  initialExpanded = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(initialExpanded);
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="border border-whiteColor overflow-hidden relative">
      <div className="col-span-1 h-[300px]">
        <img
          className="w-full h-full object-cover object-top"
          src={memberImg}
          alt=""
        />
      </div>
      <div className="w-full bg-primary text-whiteColor h-full p-4 flex flex-col gap-4">
        <h4 className={`${styles.h4Text} text-white`}>{memberName}</h4>
        <p className={`${styles.pText} text-white`}>{memberPosition}</p>
        <Button
          redirect={toggleExpand}
          customClass={
            "text-white min-w-[200px] outlne-white outline-1 hover:bg-primary hover:outline-none "
          }
          title={"show details"}
        />
      </div>
      <div
        className={`absolute bottom-0 left-0 w-full h-full z-20 p-4 flex flex-col justify-between gap-4 transition-all ${
          isExpanded ? "translate-y-0" : "translate-y-full"
        } ${
          isDarkMode
            ? "bg-blackColor text-whiteColor"
            : "bg-whiteColor text-blackColor"
        }`}
      >
        <div className="flex flex-col">
          <p className={`${styles.pText}`}>{memberDescription}</p>
        </div>
        <div className="flex flex-col gap-4">
          <p className={`${styles.pText}`}>{memberPhoneNumber}</p>
          <div className="h-[2px] bg-white w-full"></div>
          <p className={`${styles.pText}`}>{memberEmail}</p>
        </div>
        <Button
          redirect={toggleExpand}
          customClass={
            "text-white min-w-[200px] outline-none hover:text-primary "
          }
          title={"show less"}
        />
      </div>
    </div>
  );
};
