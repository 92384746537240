import React from "react";
import { styles } from "../../styles";
import Img from "../../assets/images/home/how-it-works.jpg";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import "../../assets/styles/swiper.css";
// import { FreeMode, Pagination } from "swiper/modules";
import { Autoplay } from "swiper/modules";

export const WhyChooseUs = () => {
  return (
    <div className={`w-full  bg-white  overflow-hidden`}>
      <div className={`${styles.padding} flex flex-col gap-8`}>
        <h2 className={`${styles.sectionHeadText}  text-center`}>
          Why choose us
        </h2>
        <div className="w-full flex flex-col  gap-8 mb-8  max-w-[1200px] mx-auto">
          <Swiper
            slidesPerView={2}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
            }}
            spaceBetween={30}
            freeMode={true}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            // modules={[FreeMode, Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="flex flex-col items-center text-center gap-4">
                <div className="h-[2px] bg-black w-[200px]"></div>
                <h5 className={`${styles.h5Text} text-black max-w-[250px]`}>
                  15+ Years of Experience
                </h5>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex flex-col items-center text-center gap-4">
                <div className="h-[2px] bg-black w-[200px]"></div>
                <h5 className={`${styles.h5Text} text-black max-w-[250px]`}>
                  Certified Professional Consultants and Advisors
                </h5>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex flex-col items-center text-center gap-4">
                <div className="h-[2px] bg-black w-[200px]"></div>
                <h5 className={`${styles.h5Text} text-black max-w-[250px]`}>
                  Constantly Evolving
                </h5>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex flex-col items-center text-center gap-4">
                <div className="h-[2px] bg-black w-[200px]"></div>
                <h5 className={`${styles.h5Text} text-black max-w-[250px]`}>
                  Experience in the UK and the UAE
                </h5>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex flex-col items-center text-center gap-4">
                <div className="h-[2px] bg-black w-[200px]"></div>
                <h5 className={`${styles.h5Text} text-black max-w-[250px]`}>
                  Over 1,000 Long-Term Satisfied Clients
                </h5>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex flex-col items-center text-center  gap-4">
                <div className="h-[2px] bg-black w-[200px]"></div>
                <h5 className={`${styles.h5Text} text-black max-w-[250px]`}>
                  Experience Working with Big Known Brands
                </h5>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};
