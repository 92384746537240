import React, { useState } from "react";

export const PriceAndPlnsCard = ({ styles, icon, title, desc }) => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="border border-black flex flex-col gap-8 p-4 text-black bg-whiteColor  h-[360px]">
      <div className="w-fit mx-auto flex items-center justify-center p-2 border  border-blackColor">
        {icon}
      </div>
      <h4 className={`${styles.h4Text} text-black`}>{title}</h4>
      {/* <p className={`text-lg`}>$30</p> */}
      <p
        className={`${styles.pText} overflow-y-auto scrollable-div py-2 txt-left text-justify`}
      >
        {/* {!showMore ? desc.slice(0, 100) : desc} */}
        {desc}
      </p>
      {/* <button
        onClick={() => {
          toggleShowMore();
        }}
        className={
          " w-full outline-none bg-none text-black  hover:text-primary text-sm p-0 text-center mt-auto"
        }
      >
        {!showMore ? "show more" : "show less"}
      </button> */}
    </div>
  );
};
