import React from "react";
import { styles } from "../../styles";
import { Button } from "../global/Button";
import { useHomeContext } from "../../context/HomeContext";

export const OurTeam = () => {
  const { isDarkMode } = useHomeContext();
  return (
    <>
      <div
        className={`w-full min-h-[300px] flex flex-col text-center justify-center gap-8 ${
          isDarkMode
            ? "bg-blackColor text-whiteColor"
            : "bg-whiteColor text-blackColor"
        } ${styles.padding} overflow-hidden`}
      >
        <h2 className={`${styles.sectionHeadText} text-center `}>Our Team</h2>
        <div className="w-full max-w-[500px] mx-auto">
          <p className={`${styles.pText} text-center text-justify`}>
            We are a young, innovative team of professionals with various legal
            and marketing backgrounds. Our team takes responsibility for
            delivering outstanding results.
          </p>
          <p className={`${styles.pText} text-center text-justify mt-2`}>
            We possess a forward-thinking mindset that allows us to anticipate
            industry trends and adapt strategies accordingly. Our ability to see
            the bigger picture and identify emerging opportunities is highly
            regarded.
          </p>
        </div>
      </div>
    </>
  );
};
