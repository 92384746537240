import React from "react";
import { styles } from "../../styles";

export const MarketingHero = () => {
  return (
    <div
      className={`flex flex-col items-center gap-8 w-full  bg-primary text-white top-0 left-0 ${styles.padding}`}
    >
      <div className="h-[100px] w-full bg-primary"></div>
      <div className="flex items-center justify-center mb-6">
        <div className="flex flex-col gap-8 items-center">
          <div className="w-full max-w-[400px] mx-auto flex flex-col gap-8">
            <h1 className={`${styles.hText} text-center`}>Marketing </h1>

            <p className={`${styles.pText} text-center `}>
              <q className="italic">
                &nbsp; Stopping advertising to save money is like stopping your
                watch to save time.&nbsp;
              </q>
              <br />
              <br />
              <span className="font-bold">Henry Ford</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
