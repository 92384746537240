import React, { useEffect } from "react";

export const DetectingRepeated = () => {
  const arr = [
    [4, 3, 5, 2, 6, 9, 7, 8, 1],
    [6, 8, 2, 5, 7, 1, 4, 9, 3],
    [1, 9, 7, 8, 3, 4, 5, 6, 2],
    [8, 2, 6, 1, 9, 5, 3, 4, 7],
    [3, 7, 4, 6, 8, 2, 9, 1, 5],
    [9, 5, 1, 7, 4, 3, 6, 2, 8],
    [5, 1, 9, 6, 2, 6, 8, 7, 4], // Duplicate 6 in this row
    [2, 4, 8, 9, 5, 7, 1, 3, 6],
    [7, 6, 3, 4, 1, 8, 2, 5, 9],
  ];

  const arr2 = [6, 6, 7];
  const newArr = new Set();

  for (let i = 0; i < arr2.length; i++) {
    newArr.add(arr2[i]);
    // console.log(arr2[i]);
  }
  console.log(newArr.has(6));

  //   function findDuplicates(arr) {
  //     const indicesWithDuplicates = [];

  //     for (let i = 0; i < arr.length; i++) {
  //       const innerArray = arr[i];

  //       const seen = new Set();

  //       for (let j = 0; j < innerArray.length; j++) {
  //         const num = innerArray[j];
  //         console.log(num);

  //         if (seen.has(num)) {
  //           indicesWithDuplicates.push({
  //             inerarrayindex: i,
  //             innerinerarrayindex: j,
  //           });
  //           break;
  //         }

  //         seen.add(num);
  //       }
  //     }

  //     return indicesWithDuplicates;
  //   }

  //   // Find indices of inner arrays with repeated numbers
  //   const indicesWithDuplicates = findDuplicates(arr);
  //   console.log(indicesWithDuplicates);

  //   console.log("Indices with duplicates:", indicesWithDuplicates);
  return <div className="w-full h-[500px] bg-white"></div>;
};
