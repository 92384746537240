import React from "react";
import { styles } from "../../styles";
import { useHomeContext } from "../../context/HomeContext";
import Brand1 from "../../assets/images/uk bussiness brands/brand1.jpg";
import Brand2 from "../../assets/images/uk bussiness brands/brand2.jpg";
import Brand3 from "../../assets/images/uk bussiness brands/brand3.jpg";
import Brand4 from "../../assets/images/uk bussiness brands/brand4.jpg";
import Brand5 from "../../assets/images/uk bussiness brands/brand5.jpg";
import Brand6 from "../../assets/images/uk bussiness brands/brand6.jpg";
import Brand7 from "../../assets/images/uk bussiness brands/brand7.jpg";
import Brand8 from "../../assets/images/uk bussiness brands/brand8.jpg";
import Brand9 from "../../assets/images/uk bussiness brands/brand9.jpg";
import Brand10 from "../../assets/images/uk bussiness brands/brand10.jpg";
import Brand11 from "../../assets/images/uk bussiness brands/brand11.jpg";
import Brand12 from "../../assets/images/uk bussiness brands/brand12.jpg";
import Brand13 from "../../assets/images/uk bussiness brands/brand13.jpg";
import Brand14 from "../../assets/images/uk bussiness brands/brand14.jpg";
import Brand15 from "../../assets/images/uk bussiness brands/brand15.jpg";
import Brand16 from "../../assets/images/uk bussiness brands/brand16.jpg";
import Brand17 from "../../assets/images/uk bussiness brands/brand17.jpg";
import Brand18 from "../../assets/images/uk bussiness brands/brand18.jpg";
import Brand19 from "../../assets/images/uk bussiness brands/brand19.jpg";
import Brand20 from "../../assets/images/uk bussiness brands/brand20.jpg";
// import Swiper from "swiper";
import { Autoplay } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import "../../assets/styles/swiper.css";
import { Swiper, SwiperSlide } from "swiper/react";

export const OurPartners = () => {
  const { isDarkMode } = useHomeContext();
  return (
    <div
      className={`w-full  items-center justify-center gap-4 bg-primary  ${
        isDarkMode ? "text-whiteColor" : "text-blackColor"
      } min-h-[300px]  overflow-hidden ${styles.padding}`}
    >
      <h4
        className={`${styles.hText} col-span-1 md:col-span-3 lg:col-span-5 w-full text-end mb-8`}
      >
        Our Partners
      </h4>
      <Swiper
        slidesPerView={2}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 10,
          },
        }}
        spaceBetween={10}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        // modules={[FreeMode, Pagination]}
        className="partnersSwiper"
      >
        <SwiperSlide>
          <div className="brand-box col-span-1   border-8 p-4">
            <img className="  bg-whiteColor " src={Brand1} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="brand-box col-span-1   border-8 p-4">
            <img className=" bg-whiteColor  " src={Brand2} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="brand-box col-span-1   border-8 p-4">
            <img className="  bg-whiteColor  " src={Brand3} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="brand-box col-span-1   border-8 p-4">
            <img className="  bg-whiteColor " src={Brand4} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="brand-box col-span-1  border-8 p-4">
            <img className="  bg-whiteColor " src={Brand5} alt="" />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="brand-box col-span-1  border-8 p-4">
            <img className="bg-whiteColor" src={Brand6} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="brand-box col-span-1  border-8 p-4">
            <img className="bg-whiteColor" src={Brand7} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="brand-box col-span-1  border-8 p-4">
            <img className="bg-whiteColor" src={Brand8} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="brand-box col-span-1  border-8 p-4">
            <img className="bg-whiteColor" src={Brand9} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="brand-box col-span-1  border-8 p-4">
            <img className="bg-whiteColor" src={Brand10} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="brand-box col-span-1  border-8 p-4">
            <img className="bg-whiteColor" src={Brand11} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="brand-box col-span-1  border-8 p-4">
            <img className="bg-whiteColor" src={Brand12} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="brand-box col-span-1  rounded border-8 p-8">
            <img className="bg-whiteColor" src={Brand13} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="brand-box col-span-1  rounded border-8 p-8">
            <img className="bg-whiteColor" src={Brand14} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="brand-box col-span-1  rounded border-8 p-8">
            <img className="bg-whiteColor" src={Brand15} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="brand-box col-span-1  rounded border-8 p-8">
            <img className="bg-whiteColor" src={Brand16} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="brand-box col-span-1  rounded border-8 p-8">
            <img className="bg-whiteColor" src={Brand17} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="brand-box col-span-1  rounded border-8 p-8">
            <img className="bg-whiteColor" src={Brand18} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="brand-box col-span-1  rounded border-8 p-8">
            <img className="bg-whiteColor" src={Brand19} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="brand-box col-span-1  rounded border-8 p-8">
            <img className="bg-whiteColor" src={Brand20} alt="" />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
