import React from "react";

export const LegalServicesCardLast = ({
  isDarkMode,
  styles,

  image,
  desc,
  direction,
  primary_bg,
}) => {
  return (
    <div
      className={`grid grid-cols-1 lg:grid-cols-2 overflow-hidden max-h-[700px] ${
        direction === "ltr" ? "" : "direction-rtl"
      }`}
    >
      <div className="col-span-1 h-full lg:h-full overflow-hidden">
        <img className="w-full h-full object-cover" src={image} alt="" />
      </div>
      {!primary_bg ? (
        <div
          className={`col-span-1 flex h-full w-full justify-start ${
            isDarkMode
              ? "bg-blackColor text-whiteColor"
              : "bg-whiteColor text-blackColor"
          } flex flex-col gap-8 ${styles.padding}`}
        >
          <div className="flex flex-col ">{desc}</div>
        </div>
      ) : (
        <div
          className={`col-span-1 bg-primary text-white flex flex-col gap-8 ${styles.padding} h-full justify-center`}
        >
          <div className="flex flex-col">
            <p className={`${styles.pText} `}>{desc}</p>
          </div>
        </div>
      )}
    </div>
  );
};
