import React from "react";
import { styles } from "../../styles";
import { Button } from "../global/Button";
import { AiFillChrome, AiFillCheckSquare } from "react-icons/ai";
import { MdBusinessCenter } from "react-icons/md";
import { PiBirdFill } from "react-icons/pi";
import { SiMarketo } from "react-icons/si";
import { PriceAndPlnsCard } from "../global/PriceAndPlnsCard";
import { useHomeContext } from "../../context/HomeContext";

export const PlansAndPricingParts = () => {
  const { isDarkMode } = useHomeContext();
  return (
    <>
      <div
        className={` overflow-hidden ${
          isDarkMode
            ? "bg-blackColor text-whiteColor"
            : "bg-whiteColor text-blackColor"
        } text-center ${styles.padding}`}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 max-w-[1000px] mx-auto gap-8">
          <PriceAndPlnsCard
            styles={styles}
            icon={
              <MdBusinessCenter
                className={`${styles.iconTextMain} text-blackColor`}
              />
            }
            title={"Business"}
            desc={`We offer bespoke packages for depending on the type of business that
        you’re running, where your business is at and what your goals are. Each
        business is different and unique in its own ways; therefore, we will
        review your business following an initial meeting and will let you know
        how we can assist you and what plan we can offer you depending on your
        budget.`}
          />
          <PriceAndPlnsCard
            styles={styles}
            icon={
              <AiFillCheckSquare
                className={`${styles.iconTextMain} text-blackColor`}
              />
            }
            title={"Legal"}
            desc={`  The cases will be dealt with individually and following an initial
            consultation, we will inform you of the cost to resolve your legal
            case.`}
          />
          <PriceAndPlnsCard
            styles={styles}
            icon={
              <PiBirdFill
                className={`${styles.iconTextMain} text-blackColor`}
              />
            }
            title={"Immigration"}
            desc={`The costs depend on the type of visa that you’re applying for and
            your circumstances. We will usually provide you with a fixed cost
            for handling your application from the very beginning to the very
            end.`}
          />
          <PriceAndPlnsCard
            styles={styles}
            icon={
              <SiMarketo className={`${styles.iconTextMain} text-blackColor`} />
            }
            title={"Marketing"}
            desc={` We offer bespoke packages for depending on the type of business
            that you’re running, where your business is at and what your goals
            are. Each business is different and unique in its own ways;
            therefore, we will review your business following an initial
            meeting and will let you know how we can assist you and what plan
            we can offer you depending on your budget.`}
          />
        </div>
      </div>
    </>
  );
};
