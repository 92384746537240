import React, { useState } from "react";
import ManaImg from "../../assets/images/home/mana.jpg";
import AliJamami from "../../assets/images/home/alijamami.jpg";
import KarimiImg from "../../assets/images/home/karimi.png";
import DonyaImg from "../../assets/images/home/donya.JPG";
import AmirmohammadImg from "../../assets/images/home/amirmohammad.jpg";
import AmerzamanImg from "../../assets/images/home/amerzaman.jpg";
import { styles } from "../../styles";
import { Button } from "../global/Button";
import { useHomeContext } from "../../context/HomeContext";
import { MemberCard } from "../global/MemberCard";

export const TeamMembers = () => {
  const { isDarkMode } = useHomeContext();

  return (
    <>
      <div
        className={`grid grid-cols-1 lg:grid-cols-3 gap-4 overflow-hidden ${styles.padding} bg-primary`}
      >
        <MemberCard
          memberImg={ManaImg}
          memberName={"Mona Sami"}
          memberPosition={"Founder - CEO"}
          memberDescription={
            <div className="overflow-y-scroll h-[250px]">
              <p>
                Mona started her career in the UK as a Business Development
                Executive and qualified as a Corporate Lawyer shortly after.
                Having 10+ years of experience in the Legal/Business industries,
                she decided to spread her wings and start her own business
                between the UK and the UAE.{" "}
              </p>
              <p className="mt-4">
                With an entrepreneurial background - having started her first
                business at the age of 10 - she has a ‘forward thinking’ and a
                strict ‘can do’ attitude, who will always find a solution to any
                situation put in front of her.
              </p>
              <p className="mt-4">
                Mona specialises in legal and business development for start-ups
                through to established businesses, as well as immigration and
                settlements for individuals and companies in the UK and the UAE.
              </p>
              <p className="mt-8">
                {" "}
                <i>
                  Fun fact: Mona has a number of Gold and Silver medals in
                  Taekwondo and has when she finds a spare time, she’s either
                  reading a book or recoding her next video for her YouTube
                  channel.
                </i>
              </p>
            </div>
          }
          memberPhoneNumber={"Phone: +971 58 118 3944"}
          memberEmail={"Email: Mona@ukabusiness.com"}
          styles={styles}
          button={Button}
          isDarkMode={isDarkMode}
          // initialExpanded={false}
        />
        <MemberCard
          memberImg={AmirmohammadImg}
          memberName={"AmirMohammad AmirJalali"}
          memberPosition={"Partner - Marketing"}
          memberDescription={
            <div className="overflow-y-scroll h-[250px]">
              <p>
                AmirMohammad has over 9 years of experience in marketing and
                branding and his lifestyle has completely moulded into this
                field. Having an architectural background, he started his
                marketing career as a hobby in his bedroom, which soon nourished
                into a big business with over 15 employees in only 3 years.
              </p>
              <p className="mt-4">
                AmirMohammad has an eye for finding the ‘marketing gap’ within
                businesses to create the most unique strategies for them. Using
                the latest technologies, trends and the expertise that he has
                gained over the years, he creates unique yet very effective
                marketing plans for each business, which will then be
                implemented by the team.
              </p>
              <p className="mt-4">
                He treats each business - no matter the size - as his own baby
                and gives his utmost attention to the task before him to create
                the best results for the client. His energetic, hyperactive,
                positive approach is what sets him apart within the team and
                makes him a caring member of UKA.
              </p>
              <p className="mt-8">
                <i>
                  Fun fact: AmirMohammad has an obsession with gardening and
                  apart from taking care of many plants at home, if he could, he
                  would have certainly converted the UKA office into the amazon
                  jungle.
                </i>
              </p>
            </div>
          }
          memberPhoneNumber={"Phone: +971 52 201 4323"}
          memberEmail={"Email: info@ukabusiness.com"}
          styles={styles}
          button={Button}
          isDarkMode={isDarkMode}
          // initialExpanded={true}
        />
        <MemberCard
          memberImg={KarimiImg}
          memberName={"Amir Karimi"}
          memberPosition={"Business Strategist"}
          memberDescription={
            <div className="overflow-y-scroll h-[250px]">
              <p>
                With over 15 years of experience, Amir has a meticulous approach
                to strategic planning. By relying on data-driven analysis and
                scientific methods, he ensures accuracy and effectiveness of his
                strategies across all projects in all businesses.
              </p>
              <p className="mt-4">
                Amir has a unique ability to connect with a diverse group of
                people, from start-ups seeking to establish themselves in the
                UAE, the UK and around the world, to established businesses
                looking to optimise their strategies within their region.
              </p>
              <p className="mt-4">
                Just like a curious child, Amir will not let give up until he
                has a solution to the most complicated task in front of him. He
                sees a business as a ball made of tangled threads and untangling
                that ball with a strategic solution is his expertise. Amir’s
                commitment to precision and the use of scientific methods in
                decision-making has set him apart as a leader in the field. And
                this commitment has consistently yielded positive results for
                UKA’s clients.
              </p>
              <p className="mt-8">
                <i>
                  Fun fact: Amir is obsessed with watching the older version on
                  Sherlock Holmes and has been watching it every weekend for as
                  far as he can remember! He can recall each and every catalogue
                  of by heart.
                </i>
              </p>
            </div>
          }
          memberPhoneNumber={"Phone: +971 58 118 3944"}
          memberEmail={"Email: info@ukabusiness.com"}
          styles={styles}
          button={Button}
          isDarkMode={isDarkMode}
          // initialExpanded={false}
        />
        <MemberCard
          memberImg={AliJamami}
          memberName={"Ali Jamami"}
          memberPosition={"Social Media Expert"}
          memberDescription={
            <div className="overflow-y-scroll h-[250px]">
              <p>
                Ali is the UKA’s Social Media Expert with over 6 years of
                experience in this field. His expertise lies in designing and
                crafting compelling social media strategies that drive
                engagement and growth for companies in all stages of their
                growth.
              </p>
              <p className="mt-4">
                Ali is passionate about staying up-to-date with the latest
                trends and technologies in the industry to ensure that the
                clients receive the best possible results. With a keen eye for
                detail and a commitment to excellence, he is dedicated to
                delivering exceptional results that exceed expectations.
              </p>
              <p className="mt-4">
                What sets Ali apart is his passion for the latest trends; he
                loves mixing the trends with his own ideas and what the client
                has in mind to create a unique, engaging growth potion to
                increase the client’s awareness. You will always find Ali on his
                phone, either creating an amazing social media content, or
                taking photos and videos to create the most exciting and
                intriguing social media reel.
              </p>
              <p className="mt-8">
                <i>
                  Fun Fact: When Ali is not at work, he is busy working on his
                  successful candle business or designing the latest fashion
                  apparel for his latest fashion line.
                </i>
              </p>
            </div>
          }
          memberPhoneNumber={"Phone: +971 58 118 3944"}
          memberEmail={"Email: info@ukabusiness.com"}
          styles={styles}
          button={Button}
          isDarkMode={isDarkMode}
          // initialExpanded={true}
        />
        <MemberCard
          memberImg={DonyaImg}
          memberName={"Donya Siyahatgar"}
          memberPosition={"Graphic Designer"}
          memberDescription={
            <div className="overflow-y-scroll h-[250px]">
              <p>
                Donya is a very talented Graphic Designer who lives and breathes
                in creative designs. She started graphic designing as a teenager
                and converted the hobby into a career. She specialises in
                designing graphics by combining images and texts to create the
                most unique illusions which have thus far brought in many
                business and engagement for UKA’s clients.
              </p>
              <p className="mt-4">
                Her unique approach and patience is the key to her work and her
                belief is that based on the client’s expectations, we should
                create a design that is a symbol of the business and
                automatically communicates to the audience without the business
                having to say a word.
              </p>
              <p className="mt-4">
                Donya has experience of working with some major, established
                companies and her designs can be found around exhibitions and on
                billboards around the Middle-East and the UK. Her passion for
                her work allows her to work for long hours without realising how
                many hours have passed.
              </p>
              <p className="mt-8">
                <i>
                  Fun fact: Donya bursts into a long laughter as soon as she
                  gets angry. So once she starts laughing uncontrollably, we
                  know that she is steaming, hot angry!
                </i>
              </p>
            </div>
          }
          memberPhoneNumber={"Phone: +971 58 118 3944"}
          memberEmail={"Email: info@ukabusiness.com"}
          styles={styles}
          button={Button}
          isDarkMode={isDarkMode}
          // initialExpanded={true}
        />
        <MemberCard
          memberImg={AmerzamanImg}
          memberName={"Amer Zaman"}
          memberPosition={"Immigration Solicitor"}
          memberDescription={
            <div className="overflow-y-scroll h-[250px]">
              <p>
                With over 14 years’ experience in UK Immigration Law, Amer is
                considered to be at the top of his game when advising Clients.
                His Clients describe him as “passionate”, “knowledgeable” and
                “highly recommended” when advising on UK Immigration Law.
              </p>
              <p className="mt-4">
                Amer boasts a diverse range of Clients from multinational
                corporations, High Net Worth Individuals to those fleeing
                persecution in their home countries. More recently and with the
                UK’s changing economic landscape, Amer has been advising
                technology based businesspeople from around the world on
                expanding their business operations to the UK.
              </p>
              <p className="mt-4">
                Over his extensive career in UK Immigration law, Amer’s
                reputation amongst his peers, colleagues and the industry has
                grown from strength to strength and is recognised for his
                unrelenting and tenacious spirit when representing his Clients.
              </p>
              <p className="mt-8">
                <i>
                  Fun fact: Amer is the celebrity of our team as he has
                  regularly appeared on TV, Radio and National Newspapers
                  offering comments on UK immigration related issues.
                </i>
              </p>
            </div>
          }
          memberPhoneNumber={"Phone: +971 58 118 3944"}
          memberEmail={"Email: info@ukabusiness.com"}
          styles={styles}
          button={Button}
          isDarkMode={isDarkMode}
          // initialExpanded={true}
        />
      </div>
    </>
  );
};
