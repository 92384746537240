import React from "react";
import { Hero } from "../components/home/Hero";

import { OurServices } from "../components/home/OurServices";
import { HowItWorks } from "../components/home/HowItWorks";
import { WhyChooseUs } from "../components/home/WhyChooseUs";
import { ContactUs } from "../components/home/ContactUs";
import { FixedBgComp } from "../components/home/FixedBgComp";
import { Transitions } from "../components/global/Transitions";
import { LegalServices } from "../components/home/LegalServices";
import { OurPartners } from "../components/home/OurPartners";
import { MarketingServices } from "../components/home/MarketingServices";

export const HomePage = () => {
  return (
    <Transitions>
      <Hero />
      <OurServices />
      <HowItWorks />
      <WhyChooseUs />
      <div className="flex flex-col md:flex-row w-full h-fit md:h-[400px] overflow-hidden items-start ">
        <LegalServices />
        <MarketingServices />
      </div>
      <OurPartners />
      <FixedBgComp />
      <ContactUs />
    </Transitions>
  );
};
