import React from "react";
import {
  AiFillAppstore,
  AiFillAlert,
  AiFillEdit,
  AiFillFlag,
  AiFillHome,
  AiOutlineVideoCamera,
} from "react-icons/ai";
import { FaRegHandshake } from "react-icons/fa";
import { GiTrade } from "react-icons/gi";
import { PiStudentFill } from "react-icons/pi";
import { SiMarketo } from "react-icons/si";
import {
  MdOutlineDesignServices,
  MdOutlineMonochromePhotos,
} from "react-icons/md";
import { TbBrandJuejin } from "react-icons/tb";

import { styles } from "../../styles";
import Img1 from "../../assets/images/home/1.jpg";
import Img2 from "../../assets/images/home/2.jpg";
import Img3 from "../../assets/images/home/3.jpg";
import Img4 from "../../assets/images/home/4.jpg";
import Img5 from "../../assets/images/home/5.jpg";
import { useHomeContext } from "../../context/HomeContext";

export const OurServices = () => {
  const { isDarkMode } = useHomeContext();
  return (
    <>
      <div
        className={`w-full min-h-[300px] flex-col  gap-8 ${
          isDarkMode ? "bg-blackColor" : "bg-whiteColor"
        } ${styles.padding} overflow-hidden`}
      >
        <h2
          className={`${styles.sectionHeadText} ${
            isDarkMode ? "text-whiteColor" : "text-blackColor"
          } max-w-[500px] mx-auto text-center mb-2`}
        >
          Our Services
        </h2>
        <h4
          className={`${styles.sectionSubText}  ${
            isDarkMode ? "text-whiteColor" : "text-blackColor"
          } max-w-[500px]  mx-auto text-center mb-8 mt-4`}
        >
          Our approach to our services is personal, bespoke and flexible
        </h4>
        <div className="w-full max-w-[500px] mx-auto text-justify">
          <p
            className={`${styles.pText}   ${
              isDarkMode ? "text-whiteColor" : "text-blackColor"
            }`}
          >
            U K A is a collective of the youngest and most innovative minds in
            the UAE and the UK. Our Company takes pride for the fact that it
            provides all the information and intelligence that one may need
            under one roof. We provide legal, business, immigration, and
            marketing services from consultation to execution.
          </p>
        </div>
      </div>
      <div
        className={`w-full hidden lg:grid grid-cols-5  gap-0 overflow-hidden`}
      >
        <img className="h-full object-cover" src={Img1} alt="" />
        <img className="h-full object-cover" src={Img2} alt="" />
        <img className="h-full object-cover" src={Img3} alt="" />
        <img className="h-full object-cover" src={Img4} alt="" />
        <img className="h-full object-cover" src={Img5} alt="" />
      </div>
      <div
        className={`w-full grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 justify-center bg-primary text-whiteColor  min-h-[300px] gap-0 overflow-hidden ${styles.padding}`}
      >
        <h4
          className={`${styles.sectionHeadText} col-span-1 md:col-span-3 lg:col-span-5 mb-8`}
        >
          Legal & Business
        </h4>
        <div className="flex flex-col gap-4 p-4 cursor-pointer transition hover:text-greyColor">
          <FaRegHandshake className={`${styles.iconTextMain}`} />
          <div className="h-[1px] bg-white w-full md:w-[200px] "></div>
          <p>Corporate</p>
        </div>
        <div className="flex flex-col gap-4 p-4 cursor-pointer transition hover:text-greyColor">
          <GiTrade className={`${styles.iconTextMain}`} />
          <div className="h-[1px] bg-white w-full md:w-[200px]"></div>
          <p>Commercial</p>
        </div>
        <div className="flex flex-col gap-4 p-4 cursor-pointer transition hover:text-greyColor">
          <PiStudentFill className={`${styles.iconTextMain}`} />
          <div className="h-[1px] bg-white w-full md:w-[200px]"></div>
          <p>Employment</p>
        </div>
        <div className="flex flex-col gap-4 p-4 cursor-pointer transition hover:text-greyColor">
          <AiFillFlag className={`${styles.iconTextMain}`} />
          <div className="h-[1px] bg-white w-full md:w-[200px]"></div>
          <p>Immigration</p>
        </div>
        <div className="flex flex-col gap-4 p-4 cursor-pointer transition hover:text-greyColor">
          <AiFillHome className={`${styles.iconTextMain}`} />
          <div className="h-[1px] bg-white w-full md:w-[200px]"></div>
          <p>Property</p>
        </div>
      </div>
      <div
        className={`w-full grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 justify-center  ${
          isDarkMode ? "bg-blackColor" : "bg-whiteColor"
        }  ${
          isDarkMode ? "text-whiteColor" : "text-blackColor"
        } min-h-[300px] gap-0 overflow-hidden ${styles.padding}`}
      >
        <h4
          className={`${styles.sectionHeadText} col-span-1 md:col-span-3 lg:col-span-5 text-end mb-8`}
        >
          Marketing
        </h4>
        <div className="flex flex-col gap-4 p-4 cursor-pointer transition hover:text-greyColor">
          <SiMarketo className={`${styles.iconTextMain}`} />
          <div
            className={`h-[1px]  ${
              isDarkMode ? "bg-whiteColor" : "bg-blackColor"
            }  w-full md:w-[200px]`}
          ></div>
          <p>Digital marketing & Social media management</p>
        </div>
        <div className="flex flex-col gap-4 p-4 cursor-pointer transition hover:text-greyColor">
          <AiFillEdit className={`${styles.iconTextMain}`} />
          <div
            className={`h-[1px]  ${
              isDarkMode ? "bg-whiteColor" : "bg-blackColor"
            }  w-full md:w-[200px]`}
          ></div>
          <p>Graphic design</p>
        </div>
        <div className="flex flex-col gap-4 p-4 cursor-pointer transition hover:text-greyColor">
          <MdOutlineDesignServices className={`${styles.iconTextMain}`} />
          <div
            className={`h-[1px]  ${
              isDarkMode ? "bg-whiteColor" : "bg-blackColor"
            }  w-full md:w-[200px]`}
          ></div>
          <p>Branding</p>
        </div>
        <div className="flex flex-col gap-4 p-4 cursor-pointer transition hover:text-greyColor">
          <AiOutlineVideoCamera className={`${styles.iconTextMain}`} />
          <div
            className={`h-[1px]  ${
              isDarkMode ? "bg-whiteColor" : "bg-blackColor"
            }  w-full md:w-[200px]`}
          ></div>
          <p>Trailer and commercial videos</p>
        </div>
        <div className="flex flex-col gap-4 p-4 cursor-pointer transition hover:text-greyColor">
          <MdOutlineMonochromePhotos className={`${styles.iconTextMain}`} />
          <div
            className={`h-[1px]  ${
              isDarkMode ? "bg-whiteColor" : "bg-blackColor"
            }  w-full md:w-[200px]`}
          ></div>
          <p>Photography and videography</p>
        </div>
      </div>
    </>
  );
};
