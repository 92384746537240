import React from "react";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiOutlineWhatsApp,
} from "react-icons/ai";
import { BsTiktok } from "react-icons/bs";
import { Link } from "react-router-dom";
import { styles } from "../../styles";

export const Socials = () => {
  return (
    <div className="outter-links w-full flex items-start justify-between gap-4 flex-col text-xs lg:flex-row lg:text-sm">
      <div className="flex items-start gap-4 hover:text-primary cursor-pointer">
        <Link className="" to={"/instagram/UKABusinessServices"}>
          <AiFillInstagram className={`${styles.iconTextMain}`} />
        </Link>
        <span>
          UKABusinessServices <br /> UKAMarketing
        </span>
      </div>
      <div className="flex items-start gap-4 hover:text-primary cursor-pointer">
        <Link
          className="hover:text-primary"
          to={"/facebook/UKABusinessServices"}
        >
          <AiFillFacebook className={`${styles.iconTextMain}`} />
        </Link>
        <span>
          UKABusinessServices <br /> UKAMarketing
        </span>
      </div>
      <div className="flex items-start gap-4 hover:text-primary cursor-pointer">
        <Link className="hover:text-primary" to={"/tiktok/UKABusiness"}>
          <BsTiktok className={`${styles.iconTextMain}`} />
        </Link>
        <span>UKABusiness</span>
      </div>
      <div className="flex items-start gap-4 hover:text-primary cursor-pointer">
        <Link className="hover:text-primary" to={"/whatsapp/+971 58 118 3944"}>
          <AiOutlineWhatsApp className={`${styles.iconTextMain}`} />
        </Link>
        <span>+971 58 118 3944</span>
      </div>
    </div>
  );
};
