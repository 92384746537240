import React from "react";

export const AlertCard = ({ alert, successOrNot, isDarkMode }) => {
  return (
    <div
      className={`success-email fixed transition top-10 right-10 border border-white z-20 p-2 ${
        successOrNot
          ? "bg-primary text-whiteColor"
          : "bg-blackColor text-whiteColor"
      } w-full max-w-[500px] ${alert ? "show-success-email" : ""}`}
    >
      {successOrNot
        ? "Email successfully sent"
        : "Sorry there is a problem.please try again later"}
    </div>
  );
};
